<template>
  <div class="home">
    <TopNavBar />
    <Carousel :carousels="carousels" />
    <div class="content">
      <div class="caseExhibition_box">
        <Title title="案例展示" />
        <div class="caseExhibitions">
          <div
            v-for="(item, index) in caseExhibitions"
            :key="index"
            class="item"
          >
            <div class="img_content">
              <img :src="item.imgUrl" alt="" />
              <div class="tit_content">
                <div
                  v-for="(item2, index2) in item.content"
                  :key="index2"
                  class="item_content"
                >
                  <h3 v-if="item2.title">{{ item2.title }}</h3>
                  <p v-for="(item3, index3) in item2.list" :key="index3">
                    {{ item3 }}
                  </p>
                </div>
              </div>
            </div>
            <div class="name">{{ item.name }}</div>
          </div>
        </div>
      </div>
      <div class="introduce_box">
        <Title title="流程介绍" />
        <div class="introduces">
          <template v-for="(item, index) in introduces">
            <div v-if="index % 2 === 0" :key="index" class="item top">
              <div class="img">
                <img :src="item.imgUrl" alt="" />
              </div>
              <div class="title">
                <div>{{ item.title }}</div>
                <p>{{ item.content }}</p>
              </div>
            </div>
            <div v-else :key="index" class="item bottom">
              <div class="title">
                <div>{{ item.title }}</div>
                <p>{{ item.content }}</p>
              </div>
              <div class="img">
                <img :src="item.imgUrl" alt="" />
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="advantage_box">
        <Title title="我们的优势" />
        <div class="advantages">
          <div
            v-for="(item, index) in advantages"
            :key="index"
            class="item"
            @mouseleave="advantageActive = null"
            @mouseenter="advantageActive = index"
            :style="{
              backgroundColor: advantageActive === index ? '#006eff' : '#fff',
            }"
          >
            <img
              :src="
                require('@/assets/images/home/advantage/icon' +
                  (index + '_' + (advantageActive === index ? 1 : 0)) +
                  '.png')
              "
              alt=""
            />
            <h3
              :style="{
                color: advantageActive === index ? '#fff' : '#006eff',
              }"
            >
              {{ item.title }}
            </h3>
            <p
              :style="{
                color: advantageActive === index ? '#fff' : '#333',
              }"
            >
              {{ item.content }}
            </p>
          </div>
          <img src="@/assets/images/home/advantage/bottom.png" alt="" />
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { caseExhibitions, introduces, advantages } from "@/assets/js/static.js";
export default {
  name: "Home",
  data() {
    return {
      caseExhibitions,
      introduces,
      advantages,
      advantageActive: null,
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/1.png"),
        },
        {
          imgUrl: require("@/assets/images/carousels/2.png"),
        },
        {
          imgUrl: require("@/assets/images/carousels/3.png"),
        },
        {
          imgUrl: require("@/assets/images/carousels/4.png"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.home {
  .content {
    .caseExhibition_box {
      margin-top: 80px;
      padding: 0 75px;
      .caseExhibitions {
        margin-top: 70px;
        display: grid;
        justify-items: center;
        grid-template-columns: repeat(auto-fill, minmax(540px, 1fr));
        .item {
          margin-bottom: 60px;
          .img_content {
            position: relative;
            > img {
              width: 540px;
              display: block;
            }
            .tit_content {
              display: none;
              position: absolute;
              top: 0;
              background-color: #0000008c;
              width: 500px;
              height: 100%;
              padding: 0 20px;
              color: #fff;
              .item_content {
                h3 {
                  font-size: 20px;
                  line-height: 50px;
                }
                p {
                  line-height: 34px;
                  text-align: left;
                }
              }
            }
          }
          .name {
            line-height: 80px;
            font-size: 25px;
            color: #000;
            box-shadow: 0px 0px 5px #eeeeee;
          }
        }
        .item:hover {
          .img_content {
            .tit_content {
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
          }
        }
      }
    }
    .introduce_box {
      margin-top: 50px;
      padding: 0 220px;
      .introduces {
        margin-top: 70px;
        display: flex;
        justify-content: space-between;
        .item {
          height: 500px;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 205px;
          .img {
            position: relative;
            width: 160px;
            height: 235px;
            background-repeat: no-repeat;
            background-size: cover;
            > img {
              position: absolute;
              width: 35%;
              top: 50%;
              left: 50%;
            }
          }
          .title {
            height: 170px;
            color: #333;
            > div {
              font-size: 20px;
              line-height: 50px;
            }
            p {
              font-size: 16px;
              line-height: 40px;
              font-weight: 300;
            }
          }
        }
        .top {
          justify-content: flex-end;
          .img {
            background-image: url(~@/assets/images/home/introduce/top_bg.png);
            > img {
              transform: translate(-50%, -105%);
            }
          }
        }
        .bottom {
          justify-content: flex-start;
          .img {
            background-image: url(~@/assets/images/home/introduce/bottom_bg.png);
            > img {
              transform: translate(-50%, 15%);
            }
          }
          .title {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
          }
        }
        .item:hover {
          transform: scale(1.1);
          .title {
            color: #006eff;
          }
        }
      }
    }
    .advantage_box {
      margin-top: 100px;
      margin-bottom: 80px;
      .advantages {
        margin-top: 100px;
        position: relative;
        padding: 0 120px;
        display: flex;
        justify-content: space-between;
        height: 470px;
        .item {
          width: 210px;
          height: 285px;
          background-color: #fff;
          box-shadow: 0px 0 50px #627fdf98;
          border-radius: 15px;
          padding: 30px;
          > img {
            width: 90px;
            height: 90px;
          }
          h3 {
            font-size: 20px;
            line-height: 40px;
            font-weight: 400;
          }
          p {
            font-size: 16px;
            line-height: 35px;
            font-weight: 300;
          }
        }
        > img {
          position: absolute;
          z-index: -1;
          left: 50%;
          transform: translateX(-50%);
          width: 1762px;
          height: 250px;
          bottom: 0;
        }
        .item:hover {
          transform: scale(1.15);
        }
      }
    }
  }
}
</style>
