var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('TopNavBar'),_c('Carousel',{attrs:{"carousels":_vm.carousels}}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"caseExhibition_box"},[_c('Title',{attrs:{"title":"案例展示"}}),_c('div',{staticClass:"caseExhibitions"},_vm._l((_vm.caseExhibitions),function(item,index){return _c('div',{key:index,staticClass:"item"},[_c('div',{staticClass:"img_content"},[_c('img',{attrs:{"src":item.imgUrl,"alt":""}}),_c('div',{staticClass:"tit_content"},_vm._l((item.content),function(item2,index2){return _c('div',{key:index2,staticClass:"item_content"},[(item2.title)?_c('h3',[_vm._v(_vm._s(item2.title))]):_vm._e(),_vm._l((item2.list),function(item3,index3){return _c('p',{key:index3},[_vm._v(" "+_vm._s(item3)+" ")])})],2)}),0)]),_c('div',{staticClass:"name"},[_vm._v(_vm._s(item.name))])])}),0)],1),_c('div',{staticClass:"introduce_box"},[_c('Title',{attrs:{"title":"流程介绍"}}),_c('div',{staticClass:"introduces"},[_vm._l((_vm.introduces),function(item,index){return [(index % 2 === 0)?_c('div',{key:index,staticClass:"item top"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":item.imgUrl,"alt":""}})]),_c('div',{staticClass:"title"},[_c('div',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.content))])])]):_c('div',{key:index,staticClass:"item bottom"},[_c('div',{staticClass:"title"},[_c('div',[_vm._v(_vm._s(item.title))]),_c('p',[_vm._v(_vm._s(item.content))])]),_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":item.imgUrl,"alt":""}})])])]})],2)],1),_c('div',{staticClass:"advantage_box"},[_c('Title',{attrs:{"title":"我们的优势"}}),_c('div',{staticClass:"advantages"},[_vm._l((_vm.advantages),function(item,index){return _c('div',{key:index,staticClass:"item",style:({
            backgroundColor: _vm.advantageActive === index ? '#006eff' : '#fff',
          }),on:{"mouseleave":function($event){_vm.advantageActive = null},"mouseenter":function($event){_vm.advantageActive = index}}},[_c('img',{attrs:{"src":require('@/assets/images/home/advantage/icon' +
                (index + '_' + (_vm.advantageActive === index ? 1 : 0)) +
                '.png'),"alt":""}}),_c('h3',{style:({
              color: _vm.advantageActive === index ? '#fff' : '#006eff',
            })},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('p',{style:({
              color: _vm.advantageActive === index ? '#fff' : '#333',
            })},[_vm._v(" "+_vm._s(item.content)+" ")])])}),_c('img',{attrs:{"src":require("@/assets/images/home/advantage/bottom.png"),"alt":""}})],2)],1)]),_c('Footer')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }